@import (less) "mixins.less";

ul#menu-main-navigation{
    width:100%;
    background:@aqua;
    padding:0;
    margin:0;
    display:none;
    li{
        display:block;
        .centered();
        margin:0;
        padding:0;
        a{
            display:block;
            color:#fff;
            width:100%;
            padding:15px;
            .poppins(20px);
            font-weight: 300;
            letter-spacing: .10em;
            .uppercase();
            .text-shadow();
            border-bottom:1px solid #10c0e1;
            &:hover{
                }
            }
        }
    }
    
a#menu-toggle{
    color:@aqua;
    font-size:24px;
    position:absolute;
    top:10px;
    right:10px;
    display:none;
    z-index:500;
    &.active{
        }
    }


@media only screen 
and (max-width : 900px) {
    ul#menu-main-navigation-1{
        li{
            a{font-size:12px;}
            }
        }
    }
    
@media only screen 
and (max-width : 800px) {
   a#menu-toggle{
       display:inline-block;
       }
    #menu-main-navigation-1{display:none;}
   
    }
 
@media only screen 
and (max-width : 768px) {
	#main-header{
		position:relative;
		.centered();
		img.logo{
			.inline-block();
			max-width:340px;
			width:80%;
			}
		&.active{
			.no-box-shadow();
			}
		}
	#banner{
		padding:60px 10px 60px 10px;
		background-attachment: scroll!important;
		h1{
			font-size:36px;
			span.diamond{
				display: block;
				width:100%;
				background-position: center center!important;
				margin:10px 0 10px 0!important;
				}
			}
		}
	#about-section{
		.content{
			float:none;
			width:100%;
			.centered();
			}
		}
	#testimonials-section{
		.testimonial-slider-item{
			padding:0 60px 0 60px!important;
			font-size:18px!important;
			.centered();
			.quote{
				font-size:70px;
				top:-20px;
				}
			}
		}
    .mobile-center{.centered();}
    .mobile-hide{display:none!important;}
    }
   
@media only screen 
and (max-width : 480px) {

.practice-item{
	width:100%;
	height:auto!important;
	padding:40px;
	border:none!important;
	border-bottom:2px solid #dbf3f6!important;
	.title{padding:0!important;}
	}

}